import React from 'react'
import twitter from '../imgs/twitter.png'
import insta from '../imgs/social.png'
import face from '../imgs/facebook.png'
import gmail from '../imgs/gmail.png'
import '../css/contact.css'
import {   Form } from 'react-bootstrap';

export const Contactus = () => {
  return (
    <div>
         <div className="landing_page">
      <div className="responsive-container-block big-container">
  
        <div className="responsive-container-block container">
          <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 left-one">
            <div className="content-box">
              <p className="text-blk section-head"> Lotus meta</p>
              <p className="text-blk section-subhead">
              <h4>Lotus Meta Entertainment – Redefining the Future of Entertainment</h4>
              "Innovative Ventures in NFTs, Metaverse, and Content Creation"
              </p>
              <div className="icons-container">
                <a className="share-icon" href="https://twitter.com/LotusMeta_Ent">
                  <img
                    className="img"
                    src={twitter}
                    alt="Twitter"
                  />
                </a>
                <a className="share-icon" href="https://www.facebook.com/profile.php?id=61556278206989">
                  <img
                    className="img"
                    src={face}
                    alt="facebook"
                  />
                </a>
                <a className="share-icon" href="https://www.instagram.com/lotusmeta_ent/?next=%2F">
                  <img
                    className="img"
                    src={insta}
                    alt="LME-instagram"
                  />
                </a>
                <a className="share-icon" href="mailto:ep@lotusmeta.com">
                  <img
                    className="img"
                    src={gmail}
                    alt="gmail"
                  />
                </a>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>
    </div>
  )
}
