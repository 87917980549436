import React from 'react';
import '../css/pdfview.css';
import img2 from '../imgs/Vikram Vistaverse - Website Layout_02_page-0001.jpg';
import firstpdf from '../document/Vikram Vistaverse - Website Layout_02.pdf';

export const Web3 = () => {

    const handleClick = () => {
        const pdfUrl = firstpdf;
        window.open(pdfUrl, '_blank');
      };

  return (
    <div>
        
        <div className='pdfcontainer'>
        <div className='container1'>
          <img
            src={img2} 
            alt="Vikram Vistaverse - Website Layout PDF | LME | Lotus Meta Entertainment"
            className='img1'
          />
          <p className='para'>Vikram Vistaverse - Website Layout</p>
          <button onClick={handleClick} className='btn'>Click Info</button>
          </div>
          </div>

    </div>
  )
}
