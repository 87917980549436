import React from 'react'
import '../css/ongoingprojects.css'


export const Ongoingprojects = () => {
  return (
    <div> <div className="ongoing-projects-container">
    <div className="message">
      <h1>On-Going Projects</h1>
      <p>We are currently working on some exciting projects. Stay tuned for updates!</p>
    </div>
    <div className="loader">
      <div className="spinner"></div>
    </div>
  </div></div>
  )
}
