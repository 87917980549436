import React from 'react'
import '../css/evangelist.css'
import pawan from '../imgs/pawan.jpeg';
import rakesh from '../imgs/rakesh.jpeg';
import mohan from '../imgs/mohanakrishnan.jpeg';
import abi from '../imgs/Abishek.jpeg';

export const Evangelist = () => {
  return (
    <div>
    <div className='evangelist-container'>
      <div className='line'></div>

      <h3 className='h3'>KEY COLLABORATORS</h3>

  <div className='inside-container'>

  <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={mohan} alt="lME,Lotus Meta Entertainment,Rakesh Juneja"  />
          <div className="click-me-btn">
          <h3>Mr. Mohana Krishnan</h3>
            Click for info</div>
        </div>
        <div className="flip-card-back">
        <div className='align'>
          <h3>Mr. Mohana Krishnan</h3>
          <div className='line-e'></div>
          <p>With a career spanning over 20 years in the media industry, Mr. Mohana Krishnan, founder of Yali Entertainment, has been a driving force behind some of the most innovative content in television, film, and animation.</p><p> He conceived, directed and produced projects featured on Netflix YouTube India, reflects a deep commitment to creative excellence and storytelling.
          Mr. Mohana Krishnan has held pivotal roles at leading networks such as Vijay TV and Zee TV, where his visionary approach contributed to the success of both fictional and non-fictional programming.</p>    </div>
        </div>
      </div>
    </div>


         
  <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={abi} alt="lME,Lotus Meta Entertainment, Abishek Ganesan"  />
          <div className="click-me-btn"> 

            <h3>Mr. Abishek Ganesan</h3>
            
            Click for info
            
            </div>
        </div>
        <div className="flip-card-back">
          <div className='align'>
          <h3>Mr. Abishek Ganesan</h3>
          <div className='line-e'></div>
          <p>Abishek Ganesan is an India-qualified lawyer whose practice focuses on emerging businesses and its interaction with the law. In particular, Abishek advises businesses and investors on corporate law, in domains such as disruptive technology, online gaming, artificial intelligence, fintech, e-commerce, besides others.</p>
          <p>Abja Advisory LLP is Abishek’s own entrepreneurial venture with the primary goal of providing experienced legal counsel to entrepreneurs and investors. In addition to his legal practice, Abishek is committed to staying abreast of the latest developments in technology.</p>
          </div>
        </div>
      </div>
    </div>


        
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={pawan} alt="lME,Lotus Meta Entertainment, Pawan Jailkhani"  />
          <div className="click-me-btn"> 

            <h3>Mr. Pawan Jailkhani</h3>
            
            Click for info
            
            </div>
        </div>
        <div className="flip-card-back">
          <div className='align'>
          <h3>Mr. Pawan Jailkhani</h3>
          <div className='line-e'></div>
          <p>Pawan jailkhani is a true- blue expert Brand specialist, who has mainlines on Ad-tech and content development and over the past 3 decades has worked with some of the best known brands including Zee entertainment and 9X media.</p>
          <p>A prolific angel investor with keen interest in tech/ data and content, Pawan has invested in several tech startups and has founded the first Global Ad tech platform for multi-lingual consumer. 
          Passionate about stories and consumerism, he has developed several verticals of content development and syndication across India and North America.</p>
          </div>
        </div>
      </div>
    </div>


    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={rakesh} alt="lME,Lotus Meta Entertainment,Rakesh Juneja"  />
          <div className="click-me-btn">
          <h3>Mr. Rakesh Juneja</h3>
            Click for info</div>
        </div>
        <div className="flip-card-back">
        <div className='align'>
          <h3>Mr. Rakesh Juneja</h3>
          <div className='line-e'></div>
          <p>Rakesh Juneja, the visionary producer who assumes a pivotal role in shaping the cinematic narrative from conception to completion. 
          Rakesh's journey into film production began with early training under Ad Film Makers & Music Video Producers, instilling in him an inherent understanding of detailing, pre-production intricacies, and a commitment to aesthetic values.</p>
          <p>Beyond his past experiences, whether it was OPTIMYSTIX or Vikram Bhatt’s film production house  VSB Productions LLP creatively headed by the legend MAHESH BHATT.</p>
        </div>
        </div>
      </div>
    </div>




    </div>
        </div>    
    </div>
  )
}
