import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar1 } from './pages/Navbar1';
import { Aboutus } from './pages/Aboutus';
import { Contactus } from './pages/Contactus';
import { Home1 } from './pages/Home1';
import { Footer } from './pages/Footer';
import { PdfView } from './pages/PdfView';
import { Web3 } from './pages/Web3';
import { Ongoingprojects } from './pages/Ongoingprojects';



function App() {
  return (
    <div className="App">

  <Router>
  <Navbar1 />    
          <Routes>
          <Route path="/" element={<Home1 />} />
          <Route path="/about" element={<Aboutus />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/pdfview" element={<PdfView />} />
          <Route path="/web3" element={<Web3 />} />
          <Route path="/ongoingprojects" element={<Ongoingprojects />} />
        </Routes>
        <Contactus />
        <Footer />
    </Router>  
    
    </div>
  );
}

export default App;
