import React,{useState} from 'react'
import logo from '../imgs/LME.jpg'
import '../css/navbar.css'

import { Link } from 'react-router-dom';

export const Navbar1 = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
      setMobileMenuOpen(!isMobileMenuOpen);
    };
  return (
    <div>
           <header className="header">
      <a href="/" className="logo" >
      <img src={logo} alt='logo'  loading='lazy'/>      </a>
      <nav className={`navbar ${isMobileMenuOpen ? 'active' : ''}`}>
      <Link to="/">Home</Link>
      <Link to="/ongoingprojects">On-Going Projects</Link>
     
      </nav>
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        &#9776; {/* Hamburger icon */}
      </div>
    </header>

   
   
    </div>
  )
}
