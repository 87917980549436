import React from 'react'
import '../css/aboutsuresh.css'
import suresh from '../imgs/suresh (2).jpg'

export const AboutSuresh = () => {
  return (
    <div className='back-ground'>
      
        <div className='para-title'>
          <h3> SURESH IYER – FOUNDER</h3>
        </div>
        <div className='img-Suresh'>

          <img alt='LME, Lotus Meta Entertainment,Suresh Iyer, LME-founder' src={suresh} />

        </div>

<div className='paragraph'>
<div className='para-inside'>
<p>Suresh Iyer is an accomplished media professional with over 3 
  decades experience voyaging through Broadcasting, Production 
  and Digital with expertise in developing content based on consumer
   understanding with a sharp focus on ensuring business profitability.</p>
</div>
</div>
    </div>
  )
}
