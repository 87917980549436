import React from 'react';
import '../css/pdfview.css';
import img1 from '../imgs/Uniform Civil Code _page-0001.jpg'
import img3 from '../imgs/Cumulus - Project Breakup - 23.09.2023.pptx Working - Repaired_page-0001.jpg'
import secondpdf from '../document/Uniform Civil Code .pdf';
import third from '../document/Cumulus - Project Breakup - 23.09.2023.pptx Working - Repaired.pptx'


export const PdfView = () => {
 

  const handleClick1 = () => {
    const pdfUrl = secondpdf;
    window.open(pdfUrl, '_blank');
  };

  const handleClick2 = () => {
    const pdfUrl = third;
    window.open(pdfUrl, '_blank');
  };

  return (
    
      <div className='pdfcontainer'>
        
      <div className='container2'>
        <img
          src={img1} // Replace with the URL of your image
          alt="Uniform Civil Code PDF | LME | Lotus Meta Entertainment"
          className='img2'
        />
        <p className='para'>Uniform Civil Code</p>
        <button onClick={handleClick1} className='btn'>Click Info</button>
        </div>


        <div className='container3'>
          <img
          src={img3} // Replace with the URL of your image
          alt="Cumulus - Project Breakup PDF | LME | Lotus Meta Entertainment"
          className='img3'
        />
        <p className='para'>Cumulus - Project Breakup</p>
        <button onClick={handleClick2} className="btn">Click Info</button>
        </div>

      </div>

    
  );
};